<template>
  <Layout>
    <div>
      <div class="container-fluid pt-5">
        <company-name :name="this.company.name"/>
        <div class="row">
          <BootStrapAlert/>
        </div>
        <div class="title mb-4">
          Questionnaire
        </div>
        <btn-back/>
        <div class="row questionarie">
          <div class="col-md-12 col-lg-12">
            <!-------------01------------->
            <div class="question-item-container" :class="{completed: this.questionCompletedStatus[1]}">
              <div class="question-item d-flex" @click="this.OnQuestionItemClicked(1)">
                <div class="red-no me-2">01</div>
                <div class="text">
                  Do you pay your employee W-2 wages?
                </div>
              </div>
              <div style="font-size: 12pt" class="text-danger text-end pe-5"
                   v-show="this.question.questions[1] && this.question.q1.do_u_pay === 0">
                Unfortunately you don't qualify for the ERC program
              </div>
              <div class="item-bottom fade-in-text" v-show="this.question.questions[1]">
                <input type="radio" id="_01_yes"
                       @click="this.OnClickedQ1DidYouPay(1)"
                       class="radio-custom"
                       :disabled="this.answered"
                       :checked="this.question.q1.do_u_pay === 1" name="_01">
                <label for="_01_yes" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                &nbsp;
                <input type="radio" id="_01_no"
                       @click="this.OnClickedQ1DidYouPay(0)"
                       :checked="this.question.q1.do_u_pay === 0"
                       class="radio-custom"
                       :disabled="this.answered"
                       name="_01">
                <label for="_01_no" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
              </div>
            </div>
            <template v-if="this.question.q1.do_u_pay === 1">
              <!-------------02------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[2]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(2)">
                  <div class="red-no me-2">02</div>
                  <div class="text">
                    Select Your Industry
                  </div>
                </div>

                <div class="panel-under-question-item fade-in-text" v-show="this.question.questions[2]">
                  <div class="title mb-2">Search the Industry</div>
                  <div class="search-wrapper">
                    <input type="text" class="form-control"
                           v-model="this.question.q2.industry"
                           placeholder="Search..."
                           @input="updateIndustry"
                           :disabled="this.answered"
                           ref="industry">
                  </div>
                  <div class="list-group mt-1" v-show="this.candidate_industries.length">
                    <button v-for="(industry, index) in this.candidate_industries"
                            @click="onItemIndustryClicked(index)"
                            type="button"
                            class="list-group-item list-group-item-action"
                            style="line-height: 20pt"
                            :key="index">
                      <span style="color: lightgray">{{ industry.borrowername ?? "" }}</span>
                      <br>
                      {{ industry.naicscode ?? "" }} {{ industry.naicsname ? "-" : "" }} {{ industry.naicsname ?? "" }}
                    </button>
                  </div>
                </div>
              </div>
              <!-------------03 ------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[3]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(3)">
                  <div class="red-no me-2">03</div>
                  <div class="text">
                    Did your business start before 2019?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[3]">
                  <input type="radio" id="_03_yes" name="_03"
                         :checked="this.question.q3.did_u_start_before_2019 === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         @click="this.OnClickedDidStartBefore2019(1)">
                  <label for="_03_yes" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio" id="_03_no" name="_03"
                         :checked="this.question.q3.did_u_start_before_2019 === 0"
                         class="radio-custom"
                         :disabled="this.answered"
                         @click="this.OnClickedDidStartBefore2019(0)">
                  <label for="_03_no" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[3] && this.question.q3.did_u_start_before_2019 === 0">
                  <div v-show="this.question.q3.did_u_start_before_2019 === 0">
                    <div class="bold-title mb-2">
                      Did your business start after February 15, 2020?
                    </div>
                    <div>
                      <input type="radio" id="did_start_after_2020_2_15_1"
                             @click="this.OnClickedDidStartAfter2020215(1)"
                             :checked="this.question.q3.after_2020_2_15.isAfter === 1"
                             class="radio-custom"
                             :disabled="this.answered"
                             name="did_start_after_2020_2_15">
                      <label for="did_start_after_2020_2_15_1" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                      &nbsp;
                      <input type="radio" id="did_start_after_2020_2_15_2"
                             @click="this.OnClickedDidStartAfter2020215(0)"
                             :checked="this.question.q3.after_2020_2_15.isAfter === 0"
                             class="radio-custom"
                             :disabled="this.answered"
                             name="did_start_after_2020_2_15">
                      <label for="did_start_after_2020_2_15_2" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
                    </div>
                  </div>

                  <div v-show="this.question.q3.after_2020_2_15.isAfter === 0">
                    <div class="bold-title mb-2">
                      What quarter in 2019, did the business start?
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="group">
                          <span class="title">2019</span>
                          <div>
                            <input type="radio" id="what_quarter_in_2019_1"
                                   @click="on_clicked_what_quarter(1)"
                                   :checked="this.question.q3.after_2020_2_15.what_quarter === 1"
                                   class="radio-custom"
                                   :disabled="this.answered"
                                   name="what_quarter">
                            <label for="what_quarter_in_2019_1" :class="{'not-allowed': this.answered}" class="radio-custom-label">Q1</label>
                            &nbsp;
                            <input type="radio" id="what_quarter_in_2019_2"
                                   class="radio-custom"
                                   @click="on_clicked_what_quarter(2)"
                                   :checked="this.question.q3.after_2020_2_15.what_quarter === 2"
                                   :disabled="this.answered"
                                   name="what_quarter">
                            <label for="what_quarter_in_2019_2" :class="{'not-allowed': this.answered}" class="radio-custom-label">Q2</label>
                            &nbsp;
                            <input type="radio" id="what_quarter_in_2019_3"
                                   @click="on_clicked_what_quarter(3)"
                                   :checked="this.question.q3.after_2020_2_15.what_quarter === 3"
                                   class="radio-custom"
                                   :disabled="this.answered"
                                   name="what_quarter">
                            <label for="what_quarter_in_2019_3" :class="{'not-allowed': this.answered}" class="radio-custom-label">Q3</label>
                            &nbsp;
                            <input type="radio" id="what_quarter_in_2019_4"
                                   @click="on_clicked_what_quarter(4)"
                                   class="radio-custom"
                                   :disabled="this.answered"
                                   :checked="this.question.q3.after_2020_2_15.what_quarter === 4"
                                   name="what_quarter">
                            <label for="what_quarter_in_2019_4" :class="{'not-allowed': this.answered}" class="radio-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 text-end">
                        <div class="group">
                          <span class="title">2020</span>
                          <div>
                            <input type="radio" id="what_quarter_in_2020"
                                   @click="on_clicked_what_quarter(5)"
                                   :checked="this.question.q3.after_2020_2_15.what_quarter === 5"
                                   class="radio-custom"
                                   :disabled="this.answered"
                                   name="what_quarter">
                            <label for="what_quarter_in_2020" :class="{'not-allowed': this.answered}" class="radio-custom-label">Between Jan 01 - Feb 14</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-show="this.question.q3.after_2020_2_15.isAfter === 1">
                    <div class="bold-title mb-2">
                      Explain how your business started
                    </div>
                    <div>
                      <input type="radio" id="explain_how_your_business_started_after_2020_2_15_1"
                             @click="this.explain_how_your_business_started_after_2020_2_15(1)"
                             :checked="this.question.q3.after_2020_2_15.explain_how_ur_business_started === 1"
                             class="radio-custom"
                             :disabled="this.answered"
                             name="explain_how_your_business_started_after_2020_2_15">
                      <label for="explain_how_your_business_started_after_2020_2_15_1" :class="{'not-allowed': this.answered}" class="radio-custom-label">Inherit</label>
                      &nbsp;
                      <input type="radio" id="explain_how_your_business_started_after_2020_2_15_2"
                             @click="this.explain_how_your_business_started_after_2020_2_15(2)"
                             :checked="this.question.q3.after_2020_2_15.explain_how_ur_business_started === 2"
                             class="radio-custom"
                             :disabled="this.answered"
                             name="explain_how_your_business_started_after_2020_2_15">
                      <label for="explain_how_your_business_started_after_2020_2_15_2" :class="{'not-allowed': this.answered}" class="radio-custom-label">Purchase</label>
                      &nbsp;
                      <input type="radio" id="explain_how_your_business_started_after_2020_2_15_3"
                             @click="this.explain_how_your_business_started_after_2020_2_15(3)"
                             class="radio-custom"
                             :disabled="this.answered"
                             :checked="this.question.q3.after_2020_2_15.explain_how_ur_business_started === 3"
                             name="explain_how_your_business_started_after_2020_2_15">
                      <label for="explain_how_your_business_started_after_2020_2_15_3" :class="{'not-allowed': this.answered}" class="radio-custom-label">Startup</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-------------04------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[4]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(4)">
                  <div class="red-no me-2">04</div>
                  <div class="text">
                    Did your company experience a full or partial suspension of operations?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[4]">
                  <input type="radio" id="did_ur_company_experience_full_or_partial_1"
                         @click="on_clicked_did_ur_company_experience_full_or_partial(1)"
                         :checked="this.question.q4.did_ur_company_experience_full_or_partial === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         name="did_ur_company_experience_full_or_partial">
                  <label for="did_ur_company_experience_full_or_partial_1" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio" id="did_ur_company_experience_full_or_partial_2"
                         @click="on_clicked_did_ur_company_experience_full_or_partial(0)"
                         :checked="this.question.q4.did_ur_company_experience_full_or_partial === 0"
                         class="radio-custom"
                         :disabled="this.answered"
                         name="did_ur_company_experience_full_or_partial">
                  <label for="did_ur_company_experience_full_or_partial_2" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
                </div>

                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[4] && this.question.q4.did_ur_company_experience_full_or_partial === 1">
                  <!--              <div class="title mb-2">Search the Industry</div>-->
                  <!--              <div class="search-wrapper">-->
                  <!--                <input type="text" placeholder="Search..." v-model="this.question.q4.did_ur_company_experience_full_or_partial_yes.industry">-->
                  <!--                <button type="submit"><i class="fa fa-search"></i></button>-->
                  <!--              </div>-->
                  <!--              <div class="mt-3">-->
                  <!--                <div class="item-header">-->
                  <!--                  <div class="row ps-2">-->
                  <!--                    <div class="col-md-3 col-sm-3">Name</div>-->
                  <!--                    <div class="col-md-3 col-sm-3">Essential</div>-->
                  <!--                    <div class="col-md-6 col-sm-3">Explanation</div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--                <div class="divider"></div>-->

                  <!--                <div class="ps-2 mt-2 item-04">-->
                  <!--                  <div class="row">-->
                  <!--                    <div class="col-md-3 col-sm-3">125622 - Locksmith</div>-->
                  <!--                    <div class="col-md-3 col-sm-3">Yes</div>-->
                  <!--                    <div class="col-md-6 col-sm-3">-->
                  <!--                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.-->
                  <!--                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--                <div class="divider"></div>-->
                  <!--              </div>-->


                  <div class="mt-4">
                    <div class="bold-title mb-2">
                      What quarters where you fully or partially shutdown?
                    </div>

                    <div class="row mt-3 mb-3">
                      <div class="d-flex justify-content-between">
                        <div class="group checkbox-container">
                          <span class="title">2020</span>
                          <div>
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2020_1"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2020_1">
                            <label for="what_quarters_fully_or_partially_2020_1" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2020_2"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2020_2">
                            <label for="what_quarters_fully_or_partially_2020_2" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2020_3"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2020_3">
                            <label for="what_quarters_fully_or_partially_2020_3" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2020_4"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2020_4">
                            <label for="what_quarters_fully_or_partially_2020_4" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                        <div class="group checkbox-container">
                          <span class="title">2021</span>
                          <div>
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2021_1"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2021_1">
                            <label for="what_quarters_fully_or_partially_2021_1" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2021_2"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2021_2">
                            <label for="what_quarters_fully_or_partially_2021_2" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2021_3"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2021_3">
                            <label for="what_quarters_fully_or_partially_2021_3" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox" @click="on_clicked_what_quarters_fully_or_partially_2021_4"
                                   :checked="this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_fully_or_partially_2021_4">
                            <label for="what_quarters_fully_or_partially_2021_4" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="title mb-2">Explanation</div>
                        <textarea v-model="this.question.q4.did_ur_company_experience_full_or_partial_yes.explanation"
                                  class="textarea form-control"
                                  :disabled="this.answered"
                                  rows="5" placeholder="Type in"></textarea>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-------------05------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[5]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(5)">
                  <div class="red-no me-2">05</div>
                  <div class="text">
                    Did you have any supply chain disruption
                  </div>
                </div>

                <div class="item-bottom fade-in-text" v-show="this.question.questions[5]">
                  <input type="radio" id="did_u_have_any_supply_chain_disruption_yes"
                         :checked="this.question.q5.did_u_have_any_supply_chain_disruption === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         name="did_u_have_any_supply_chain_disruption"
                         @click="this.on_click_did_u_have_any_supply_chain_disruption(1)">
                  <label for="did_u_have_any_supply_chain_disruption_yes" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio" id="did_u_have_any_supply_chain_disruption_no"
                         :checked="this.question.q5.did_u_have_any_supply_chain_disruption === 0"
                         name="did_u_have_any_supply_chain_disruption"
                         class="radio-custom"
                         :disabled="this.answered"
                         @click="this.on_click_did_u_have_any_supply_chain_disruption(0)">
                  <label for="did_u_have_any_supply_chain_disruption_no" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[5] && this.question.q5.did_u_have_any_supply_chain_disruption === 1">
                  <div>
                    <div class="bold-title mb-2">What was disrupted?</div>
                    <div class="search-wrapper">
                      <input type="text"
                             class="form-control"
                             :disabled="this.answered"
                             v-model="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_was_disrupted"
                             placeholder="Type in...">
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="bold-title mb-2">From what supplier process?</div>
                    <div class="search-wrapper">
                      <input
                          class="form-control"
                          :disabled="this.answered"
                          v-model="this.question.q5.did_u_have_any_supply_chain_disruption_yes.from_what_supplier_process"
                          type="text" placeholder="Type in...">
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="bold-title mb-2">What quarters did you experience supply disruption?</div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="group checkbox-container">
                          <span class="title">2020</span>
                          <div>
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_did_u_experience_supply_disruption_2020_1"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2020_1">
                            <label for="what_quarters_did_u_experience_supply_disruption_2020_1" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_did_u_experience_supply_disruption_2020_2"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2020_2">
                            <label for="what_quarters_did_u_experience_supply_disruption_2020_2" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q3"
                                   id="what_quarters_did_u_experience_supply_disruption_2020_3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2020_3">
                            <label for="what_quarters_did_u_experience_supply_disruption_2020_3" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q4"
                                   id="what_quarters_did_u_experience_supply_disruption_2020_4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2020_4">
                            <label for="what_quarters_did_u_experience_supply_disruption_2020_4" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>


                      <div class="col-md-6 text-end">
                        <div class="group checkbox-container">
                          <span class="title">2021</span>
                          <div>
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q1"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2021_1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_did_u_experience_supply_disruption_2021_1">
                            <label for="what_quarters_did_u_experience_supply_disruption_2021_1" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q2"
                                    class="checkbox-custom"
                                   :disabled="this.answered"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2021_2"
                                   id="what_quarters_did_u_experience_supply_disruption_2021_2">
                            <label for="what_quarters_did_u_experience_supply_disruption_2021_2" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q3"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2021_3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_did_u_experience_supply_disruption_2021_3">
                            <label for="what_quarters_did_u_experience_supply_disruption_2021_3" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   :checked="this.question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q4"
                                   @click="this.on_click_what_quarters_did_u_experience_supply_disruption_2021_4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_did_u_experience_supply_disruption_2021_4">
                            <label for="what_quarters_did_u_experience_supply_disruption_2021_4" :class="{'not-allowed': this.answered}" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-------------06------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[6]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(6)">
                  <div class="red-no me-2">06</div>
                  <div class="text">
                    Did you have to pivot one or more of your practices more than 10% to succeed during COVID?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[6]">
                  <input
                      @click="this.on_click_did_u_have_pivot(1)"
                      :checked="this.question.q6.did_u_have_pivot === 1"
                      class="radio-custom"
                      :disabled="this.answered"
                      type="radio" id="did_u_have_pivot_yes" name="did_u_have_pivot">
                  <label for="did_u_have_pivot_yes" :class="{'not-allowed': this.answered}" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input
                      @click="this.on_click_did_u_have_pivot(0)"
                      :checked="this.question.q6.did_u_have_pivot === 0"
                      class="radio-custom"
                      :disabled="this.answered"
                      type="radio" id="did_u_have_pivot_no" name="did_u_have_pivot">
                  <label for="did_u_have_pivot_no" :class="{'not-allowed': this.answered}" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[6] && this.question.q6.did_u_have_pivot === 1">
                  <div>
                    <div class="bold-title mb-2">How did you pivot?</div>
                    <div class="search-wrapper">
                      <input
                          v-model="this.question.q6.did_u_have_pivot_yes.how_did_u_pivot"
                          :disabled="this.answered"
                          type="text" placeholder="Type in...">
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="bold-title mb-2">What quarters did you experience supply disruption?</div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="group checkbox-container">
                          <span class="title">2020</span>
                          <div>
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_1"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2020_1">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2020_1" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_2"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2020_2">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2020_2" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_3"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2020_3">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2020_3" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_4"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2020_4">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2020_4" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 text-end">
                        <div class="group checkbox-container">
                          <span class="title">2021</span>
                          <div>
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_1"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2021_1">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2021_1" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q2"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2021_2">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2021_2" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q3"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2021_3">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2021_3" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_4"
                                   :checked="this.question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="pivot_what_quarters_did_u_experience_supply_disruption_2021_4">
                            <label :class="{'not-allowed': this.answered}" for="pivot_what_quarters_did_u_experience_supply_disruption_2021_4" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-------------07------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[7]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(7)">
                  <div class="red-no me-2">07</div>
                  <div class="text">
                    Did you get a PPP Loan?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[7]">
                  <input type="radio"
                         @click="on_click_did_u_get_ppp_loan(1)"
                         :checked="this.question.q7.did_u_get_ppp_loan === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="did_u_get_ppp_loan_yes" name="did_u_get_ppp_loan">
                  <label :class="{'not-allowed': this.answered}" for="did_u_get_ppp_loan_yes" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio"
                         @click="on_click_did_u_get_ppp_loan(0)"
                         :checked="this.question.q7.did_u_get_ppp_loan === 0"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="did_u_get_ppp_loan_no" name="did_u_get_ppp_loan">
                  <label :class="{'not-allowed': this.answered}" for="did_u_get_ppp_loan_no" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[7] && this.question.q7.did_u_get_ppp_loan === 1">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="q7-list-panel">
                        <template v-for="(item, index) in this.question.q7.did_u_get_ppp_loan_yes.loans" :key="index">
                          <div class="list-header d-flex justify-content-between">
                            <div class="cursor-pointer" @click="() =>
                          {
                            this.isShowedLoan = this.isShowedLoan === index ? -1 : index;
                          }">
                              {{
                                `${item.borrowername} - ${item.borrowercity}
                            ${item.borrowerstate} - ${this.usDollar(item.payroll_proceed)}`
                              }}
                            </div>
                            <span @click="OnBtnClickedRemoveLoan(index)" class="q7-btn-remove ms-2"></span>
                          </div>
                          <div class="row" v-show="this.isShowedLoan === index">
                            <div class="col-6">
                              <div class="list-item d-flex justify-content-between">
                                <span>PPPLoan</span>
                                <span>{{this.usDollar(item.currentapprovalamount)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Payroll</span>
                                <span>{{this.usDollar(item.payroll_proceed)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Utilities</span>
                                <span>{{this.usDollar(item.utilities_proceed)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Mortgage</span>
                                <span>{{this.usDollar(item.mortgage_interest_proceed)}}</span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="list-item d-flex justify-content-between">
                                <span>Health Care</span>
                                <span>{{this.usDollar(item.health_care_proceed)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Rent</span>
                                <span>{{this.usDollar(item.rent_proceed)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Refinance</span>
                                <span>{{this.usDollar(item.refinance_eidl_proceed)}}</span>
                              </div>
                              <div class="list-item d-flex justify-content-between">
                                <span>Debt Interest</span>
                                <span>{{this.usDollar(item.debt_interest_proceed)}}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 company-name-input">
                      <div class="title mb-2">Company Name</div>
                      <div class="search-wrapper">
                        <input
                            id="company_name"
                            ref="company_name"
                            v-model="this.loan.borrowername"
                            @input="this.updateCompanyName"
                            :disabled="this.answered"
                            class="form-control" type="text">
                        <span :class="{'not-allowed': this.answered}" @click="OnBtnClickedAddLoan" class="btn-sign-primary plus ms-2"></span>
                      </div>

                      <div class="list-group mt-1 company-name-drop-down-list"
                           v-show="this.loans.length">
                        <button v-for="(loan, index) in this.loans"
                                @click="onItemCompanyClicked(index)"
                                :disabled="this.answered"
                                type="button" class="list-group-item list-group-item-action" :key="index">
                          {{
                            `${loan.borrowername} - ${loan.borrowercity}
                            ${loan.borrowerstate} - ${this.usDollar(loan.payroll_proceed)}`
                          }}
                        </button>
                      </div>

                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Loan Date</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.dateapproved"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">PPPLoan</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.currentapprovalamount"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Utilities</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.utilities_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Payroll</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.payroll_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Mortgage</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.mortgage_interest_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Rent</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.rent_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Refinance</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.refinance_eidl_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Healthcare</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.health_care_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4 mt-2">
                      <div class="title mb-2">Debt int</div>
                      <div class="search-wrapper">
                        <input
                            v-model="this.loan.debt_interest_proceed"
                            :disabled="this.answered"
                            class="form-control" type="text">
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <!-------------08------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[8]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(8)">
                  <div class="red-no me-2">08</div>
                  <div class="text">
                    Other than PPP Loan have you received any other ERC advancement?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[8]">
                  <input type="radio"
                         @click="this.on_click_have(1)"
                         :checked="this.question.q8.have === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="have_yes" name="have">
                  <label :class="{'not-allowed': this.answered}" for="have_yes" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio"
                         @click="this.on_click_have(0)"
                         :checked="this.question.q8.have === 0"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="have_no" name="have">
                  <label :class="{'not-allowed': this.answered}" for="have_no" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[8] && this.question.q8.have === 1">
                  <div class="title-under-8">2700 form</div>
                  <div class="mt-4">
                    <div class="bold-title mb-2">
                      What quarters they file for ?
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="group checkbox-container">
                          <span class="title">2020</span>
                          <div>
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2020_1"
                                   :checked="this.question.q8.have_yes.what_quarters_2020.q1"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_2020_1">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2020_1" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2020_2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   :checked="this.question.q8.have_yes.what_quarters_2020.q2"
                                   id="what_quarters_2020_2">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2020_2" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2020_3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   :checked="this.question.q8.have_yes.what_quarters_2020.q3"
                                   id="what_quarters_2020_3">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2020_3" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2020_4"
                                   :checked="this.question.q8.have_yes.what_quarters_2020.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_2020_4">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2020_4" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 text-end">
                        <div class="group checkbox-container">
                          <span class="title">2021</span>
                          <div>
                            <input type="checkbox"
                                   class="checkbox-custom"
                                   @click="this.on_click_what_quarters_2021_1"
                                   :checked="this.question.q8.have_yes.what_quarters_2021.q1"
                                   :disabled="this.answered"
                                   id="what_quarters_2021_1">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2021_1" class="checkbox-custom-label">Q1</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2021_2"
                                   :checked="this.question.q8.have_yes.what_quarters_2021.q2"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_2021_2">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2021_2" class="checkbox-custom-label">Q2</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2021_3"
                                   :checked="this.question.q8.have_yes.what_quarters_2021.q3"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_2021_3">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2021_3" class="checkbox-custom-label">Q3</label>
                            &nbsp;
                            <input type="checkbox"
                                   @click="this.on_click_what_quarters_2021_4"
                                   :checked="this.question.q8.have_yes.what_quarters_2021.q4"
                                   class="checkbox-custom"
                                   :disabled="this.answered"
                                   id="what_quarters_2021_4">
                            <label :class="{'not-allowed': this.answered}" for="what_quarters_2021_4" class="checkbox-custom-label">Q4</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-------------09------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[9]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(9)">
                  <div class="red-no me-2">09</div>
                  <div class="text">
                    What percentage of this company do you own?
                  </div>
                </div>

                <div class="panel-under-question-item fade-in-text" v-show="this.question.questions[9]">
                  <div>
                    <div class="title-under-8">Add a New Partner</div>
                    <div class="divider"></div>
                  </div>
                  <div class="mt-4">
                    <div class="bold-title">Please list owner/shareholder of the company</div>
                    <div class="row">
                      <BootStrapAlert aflag="question9"/>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="title mt-3 mb-3">Owner/Shareholder</div>
                        <div class="search-wrapper">
                          <input class="form-control" type="text"
                                 :disabled="this.answered"
                                 v-model="this.member.owner">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="title mt-3 mb-3">Percentage</div>
                        <div class="search-wrapper">
                          <input class="form-control"
                                 :disabled="this.answered"
                                 type="number" v-model="this.member.percentage">
                          <span class="btn-add" @click="on_click_add_member">+</span>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="bold-title">Please select family members who are owners</div>
                    <div class="list-panel mt-2">
                      <div class="list-header fade-in-text">
                        <input type="checkbox"
                               :checked="this.isQ9SelectedAll"
                               class="checkbox-custom"
                               :disabled="this.answered"
                               id="select_all_members" @click="on_click_select_all">
                        <label :class="{'not-allowed': this.answered}" style="font-size: 13pt; font-weight: normal" for="select_all_members" class="checkbox-custom-label">Select All</label>
                      </div>


                      <div class="list-item d-flex justify-content-between"
                           v-for="(item, index) in this.question.q9.members" :key="index">
                        <input type="checkbox" :checked="item.checked"
                               class="checkbox-custom"
                               :id="index" @click="on_click_check(item)">
                        <label style="font-size: 13pt; font-weight: normal"
                               :for="index" class="checkbox-custom-label">
                          {{ item.owner }}
                          ({{ item.percentage }}%)
                        </label>

                        <span class="btn-add mt-3" @click="on_click_remove_member(index)">-</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-------------10------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[10]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(10)">
                  <div class="red-no me-2">10</div>
                  <div class="text">
                    What is your title at the company?
                  </div>
                </div>

                <div class="panel-under-question-item fade-in-text" v-show="this.question.questions[10]">
                  <div>
                    <div class="bold-title mb-2">Title at the Company</div>
                    <div class="search-wrapper">
                      <input class="form-control"
                             :disabled="this.answered"
                             type="text" placeholder="Type in..."
                             v-model="this.question.q10.title">
                    </div>
                  </div>
                </div>
              </div>
              <!-------------11------------->
              <div class="question-item-container" :class="{completed: this.questionCompletedStatus[11]}">
                <div class="question-item d-flex" @click="this.OnQuestionItemClicked(11)">
                  <div class="red-no me-2">11</div>
                  <div class="text">
                    Do you owe the IRS any back taxes?
                  </div>
                </div>
                <div class="item-bottom fade-in-text" v-show="this.question.questions[11]">
                  <input type="radio"
                         @click="this.on_click_do_u_owe(1)"
                         :checked="this.question.q11.do_u_owe === 1"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="do_u_owe_yes" name="do_u_owe">
                  <label :class="{'not-allowed': this.answered}" for="do_u_owe_yes" class="radio-custom-label">Yes</label>
                  &nbsp;
                  <input type="radio"
                         @click="this.on_click_do_u_owe(0)"
                         :checked="this.question.q11.do_u_owe === 0"
                         class="radio-custom"
                         :disabled="this.answered"
                         id="do_u_owe_no" name="do_u_owe">
                  <label :class="{'not-allowed': this.answered}" for="do_u_owe_no" class="radio-custom-label">No</label>
                </div>
                <div class="panel-under-question-item fade-in-text"
                     v-show="this.question.questions[11] && this.question.q11.do_u_owe === 1">
                  <div>
                    <div class="bold-title mb-2">How much do you owe?</div>
                    <div class="search-wrapper">
                      <input class="form-control" type="number" placeholder="Type in..."
                             :disabled="this.answered"
                             v-model="this.question.q11.do_u_owe_yes.owe_amount">
                    </div>
                  </div>

                </div>
              </div>
              <!-------------end 11 end------------->
            </template>

            <div class="">
              <div class="question-item d-flex justify-content-center mt-5" style="margin-bottom: 130px">
                <button v-if="!this.answered" class="submit"
                        :class="{'disabled' : btnSubmitDisabled}"
                        @click="this.onSubmit" :disabled="btnSubmitDisabled">
                  Submit
                </button>

                <button v-else class="submit"
                        @click="this.goToDocuments">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import Layout from "@/layouts/main";
import {mapGetters, mapActions} from "vuex";
import BootStrapAlert from "@/components/utils/Alert";
import loanApi from "@/apis/loan-api";
import {industries as industs} from "@/static/industries.js"
import btnBack from "@/components/btn-back/btnBack";
import utilsFunc from "@/utils/functions";


import {
  authMethods,
} from "@/state/helpers";
import CompanyName from "../../../../components/company-name/company-name.vue";

const initLoan = {
  dateapproved: "",
  processingmethod: "",
  borrowername: "",
  borroweraddress: "",
  borrowercity: "",
  borrowerstate: "",
  borrowerzip: "",
  loanstatusdate: "",
  loanstatus: "",
  term: 0,
  currentapprovalamount: 0,
  servicinglendername: "",
  jobsreported: 0,
  naicscode: 0,
  utilities_proceed: 0,
  payroll_proceed: 0,
  mortgage_interest_proceed: 0,
  rent_proceed: 0,
  refinance_eidl_proceed: 0,
  health_care_proceed: 0,
  debt_interest_proceed: 0,
  businesstype: "",
  nonprofit: "",
  forgivenessdate: "",
}

export default {
  props: ['id'],
  name: "Questionnaire",
  data() {
    return {
      loan: {...initLoan}, // question 7
      loans: [], // question 7
      isShowedLoan: -1,

      company: {
        name: '',
      },
      answered: 0,
      submitEnabled: false,
      candidate_industries: [], // candidate industries
      currentPos: 0,
      questionCompletedStatus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // It makes sure that each question is completed
      question: {
        application_id: this.id,
        id: 0,
        q_pos: 0,
        questions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        q1: {
          do_u_pay: -1,
        },
        q2: {
          industry: ""
        },
        q3: {
          did_u_start_before_2019: -1,
          after_2020_2_15: {
            isAfter: -1,
            explain_how_ur_business_started: -1, // 1: inherit, 2: purchase, 3: startup,
            what_quarter: -1, // 1: Q1, 2: Q2, 3: Q3, 4: Q4, 5: Between Jan 01 - Feb 14
          }
        },
        q4: {
          did_ur_company_experience_full_or_partial: -1,
          did_ur_company_experience_full_or_partial_yes: {
            industry: "",
            what_quarters_fully_or_partially_2020: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
            what_quarters_fully_or_partially_2021: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
            explanation: "",
          }
        },
        q5: {
          did_u_have_any_supply_chain_disruption: -1,
          did_u_have_any_supply_chain_disruption_yes: {
            what_was_disrupted: "",
            from_what_supplier_process: "",
            what_quarters_did_u_experience_supply_disruption_2020: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
            what_quarters_did_u_experience_supply_disruption_2021: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
          },
        },
        q6: {
          did_u_have_pivot: -1,
          did_u_have_pivot_yes: {
            how_did_u_pivot: "",
            pivot_what_quarters_did_u_experience_supply_disruption_2020: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
            pivot_what_quarters_did_u_experience_supply_disruption_2021: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
          }
        },
        q7: {
          did_u_get_ppp_loan: -1,
          did_u_get_ppp_loan_yes: {
            loans: [],
          },
        },
        q8: {
          have: -1,
          have_yes: {
            what_quarters_2020: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            },
            what_quarters_2021: {
              q1: false,
              q2: false,
              q3: false,
              q4: false
            }
          }
        },
        q9: {
          members: []
        },
        q10: {
          title: ""
        },
        q11: {
          do_u_owe: -1,
          do_u_owe_yes: {
            owe_amount: 0
          }
        },
      },

      // it is related to question 9
      member: {
        owner: "",
        percentage: 0.0,
        checked: false
      }
    }
  },
  methods: {
    ...authMethods,
    ...mapActions("alert", ["setAlert"]),
    usDollar: utilsFunc.usDollar,
    toDateString: utilsFunc.toDateString,
    decimalDollar: utilsFunc.decimalDollar,
    calculateBannerSize() {
      if (window.innerWidth >= 900) {
        let p = document.getElementById("table");
        let style = window.getComputedStyle(p);
        let width = 0.5 * (window.innerWidth - parseInt(style.width)) - 20;
        this.banner_width = width + "px";
        this.banner_height = (width * 1.68) + 160 + "px";
        document.getElementById("banner1").style.width = this.banner_width;
        document.getElementById("banner2").style.width = this.banner_width;
        document.getElementById("banner2").style.position = "fixed";
        document.getElementById("banner2").style.top = this.banner_height;
      } else {
        document.getElementById("banner2").style.position = "unset";
        document.getElementById("banner1").style.width = "136px";
        document.getElementById("banner2").style.width = "136px";
      }
    },

    OnQuestionItemClicked(pos) {
      this.currentPos = pos;
      this.question.q_pos = pos;
      this.question.questions[pos] = this.question.questions[pos] !== 0 ? 0 : 1;

      switch (pos) {
        case 2:
          if (this.question.questions[2] && !this.question.q2.industry)
            this.OnInitIndustry(this.company.name);
          break;
        case 7:

          break;
      }

      this.updateQuestion();
    },
    //questions 1
    OnClickedQ1DidYouPay(number) {
      this.currentPos = number === 1 ? 2 : 1;
      this.question.q1.do_u_pay = number;

      this.updateQuestion();
    },

    //questions 2
    async updateIndustry(e) {
      this.question.q2.industry = e.target.value;
      await loanApi.searchIndustry(e.target.value).then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {industries, industry} = data;
          if (this.$refs.industry.value !== industry) return;
          if (industries.length)
            this.candidate_industries = industries;
          else {
            if (e.target.value != "")
              this.candidate_industries = industs;
            else
              this.candidate_industries = [];
          }
        }
      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        // this.$isLoading(false);
      });
    },

    onItemIndustryClicked(pos) {
      let industry = this.candidate_industries[pos];
      const {naicscode, naicsname} = industry;
      this.question.q2.industry = naicsname ? `${naicscode}-${naicsname}` : `${naicscode}`;
      this.candidate_industries = [];

      this.updateQuestion();
    },

    async OnInitIndustry(c_name) {
      this.$isLoading(true);
      await loanApi.searchIndustry(c_name).then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {industries} = data;
          if (industries.length) {
            let industry = industries[0];
            const {naicscode, naicsname} = industry;
            this.question.q2.industry = naicsname ? `${naicscode}-${naicsname}` : naicscode ? `${naicscode}` : '';
          } else {
            if (c_name != "") {
              let industry = industs[0];
              const {naicscode, naicsname} = industry;
              this.question.q2.industry = naicsname ? `${naicscode}-${naicsname}` : `${naicscode}`;
            }
          }
        }
      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        this.$isLoading(false);
      });
    },
    // question 3
    OnClickedDidStartBefore2019(number) {
      this.currentPos = number === 1 ? 4 : 3;
      this.question.q_pos = this.currentPos;

      this.question.q3.did_u_start_before_2019 = number;

      this.updateQuestion();
    },
    OnClickedDidStartAfter2020215(number) {
      this.question.q3.after_2020_2_15.isAfter = number;

      this.updateQuestion();
    },
    explain_how_your_business_started_after_2020_2_15(number) {
      this.question.q3.after_2020_2_15.explain_how_ur_business_started = number;

      this.updateQuestion();
    },
    on_clicked_what_quarter(number) {
      this.question.q3.after_2020_2_15.what_quarter = number;

      this.updateQuestion();
    },

    //questions 4
    on_clicked_did_ur_company_experience_full_or_partial(number) {
      this.currentPos = number === 0 ? 5 : 4;
      this.question.q_pos = this.currentPos;

      this.question.q4.did_ur_company_experience_full_or_partial = number;

      this.updateQuestion();
    },

    on_clicked_what_quarters_fully_or_partially_2020_1() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q1
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q1;

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2020_2() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q2
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q2

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2020_3() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q3
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q3

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2020_4() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q4
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q4;

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2021_1() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q1
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q1;

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2021_2() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q2
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q2;

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2021_3() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q3
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q3;

      this.updateQuestion();
    },
    on_clicked_what_quarters_fully_or_partially_2021_4() {
      this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q4
          = !this.question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q4;

      this.updateQuestion();
    },

    async getQuestion() {
      this.$isLoading(true);
      await loanApi.getQuestion(this.id).then(response => {
        const {status, data} = response;
        if (status === "success") {
          const {question, company, answered} = data;
          this.question = question == null ? this.question : JSON.parse(question);
          this.currentPos = this.question.q_pos;
          this.company = company;
          this.answered = answered
        }
      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        this.$isLoading(false);
      });
    },
    updateQuestion(submit = 0) {
      if (this.answered === 1) return

      loanApi.updateQuestion(submit, this.id, JSON.stringify(this.question))
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              // Handle 400 error
              const {message} = error.response.data;
              if (message === "JWT Expired") {
                this.logOut();
                this.$router.push({
                  name: "login"
                });
              } else
                alert(message);
            } else {
              // Handle other errors
              console.log("An error occurred:", error.message);
            }
          }).finally(() => {
        this.$isLoading(false);
      })
    },


    // question 5
    on_click_did_u_have_any_supply_chain_disruption(number) {
      this.question.q5.did_u_have_any_supply_chain_disruption = number;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2020_1() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2020.q1 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2020.q1;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2020_2() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2020.q2 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2020.q2;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2020_3() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2020.q3 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2020.q3;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2020_4() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2020.q4 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2020.q4;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2021_1() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2021.q1 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2021.q1;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2021_2() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2021.q2 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2021.q2;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2021_3() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2021.q3 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2021.q3;

      this.updateQuestion();
    },

    on_click_what_quarters_did_u_experience_supply_disruption_2021_4() {
      this.question.q5.did_u_have_any_supply_chain_disruption_yes
          .what_quarters_did_u_experience_supply_disruption_2021.q4 =
          !this.question.q5.did_u_have_any_supply_chain_disruption_yes
              .what_quarters_did_u_experience_supply_disruption_2021.q4;

      this.updateQuestion();
    },

    // question 6
    on_click_did_u_have_pivot(number) {
      this.question.q6.did_u_have_pivot = number;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_1() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2020.q1 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2020.q1;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_2() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2020.q2 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2020.q2;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_3() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2020.q3 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2020.q3;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2020_4() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2020.q4 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2020.q4;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_1() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2021.q1 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2021.q1;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_2() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2021.q2 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2021.q2;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_3() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2021.q3 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2021.q3;

      this.updateQuestion();
    },

    on_click_pivot_what_quarters_did_u_experience_supply_disruption_2021_4() {
      this.question.q6.did_u_have_pivot_yes
          .pivot_what_quarters_did_u_experience_supply_disruption_2021.q4 =
          !this.question.q6.did_u_have_pivot_yes
              .pivot_what_quarters_did_u_experience_supply_disruption_2021.q4;

      this.updateQuestion();
    },

    // question 7
    async on_click_did_u_get_ppp_loan(number) {
      this.question.q7.did_u_get_ppp_loan = number;
      if (number && !this.question.q7.did_u_get_ppp_loan_yes.loans.length)
         await this.getLoans();

      this.updateQuestion();
    },

    async updateCompanyName(e) {
      this.company_name = e.target.value;
      await loanApi.searchPPP(e.target.value).then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {companies, company_name} = data;
          if (this.$refs.company_name.value !== company_name) return;
          this.loans = companies;
        }

      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        // this.$isLoading(false);
      });
    },

    onItemCompanyClicked(pos) {
      const loan = this.loans[pos];
      this.loan.borrowerstate = loan.borrowerstate;
      this.loan.borrowercity = loan.borrowercity;
      this.loan.dateapproved = this.toDateString(loan.dateapproved);
      this.loan.borrowername = loan.borrowername;
      this.loan.currentapprovalamount = this.decimalDollar(loan.currentapprovalamount);
      this.loan.utilities_proceed = this.decimalDollar(loan.utilities_proceed) | 0;
      this.loan.payroll_proceed = this.decimalDollar(loan.payroll_proceed) | 0;
      this.loan.mortgage_interest_proceed = this.decimalDollar(loan.mortgage_interest_proceed) | 0;
      this.loan.rent_proceed = this.decimalDollar(loan.rent_proceed) | 0;
      this.loan.refinance_eidl_proceed = this.decimalDollar(loan.refinance_eidl_proceed) | 0;
      this.loan.health_care_proceed = this.decimalDollar(loan.health_care_proceed) | 0;
      this.loan.debt_interest_proceed = this.decimalDollar(loan.debt_interest_proceed) | 0;
      this.loans = [];
    },

    OnBtnClickedAddLoan() {
      if (!this.loan.borrowername) return;
      this.question.q7.did_u_get_ppp_loan_yes.loans.push({...this.loan});
      this.loan = {...initLoan}
      this.updateQuestion();
    },

    OnBtnClickedRemoveLoan(pos) {
      if (this.answered === 1) return
      this.question.q7.did_u_get_ppp_loan_yes.loans =
          this.question.q7.did_u_get_ppp_loan_yes.loans.filter((item, index) => pos !== index);
      this.updateQuestion();
    },

    async getLoans() {
      this.$isLoading(true);
      await loanApi.searchPPP(this.company.name).then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {companies} = data;
          this.question.q7.did_u_get_ppp_loan_yes.loans = companies.filter(item => {
            item.dateapproved = this.toDateString(item.dateapproved);
            item.currentapprovalamount = this.decimalDollar(item.currentapprovalamount);
            item.utilities_proceed = this.decimalDollar(item.utilities_proceed) | 0;
            item.payroll_proceed = this.decimalDollar(item.payroll_proceed) | 0;
            item.mortgage_interest_proceed = this.decimalDollar(item.mortgage_interest_proceed) | 0;
            item.rent_proceed = this.decimalDollar(item.rent_proceed) | 0;
            item.refinance_eidl_proceed = this.decimalDollar(item.refinance_eidl_proceed) | 0;
            item.health_care_proceed = this.decimalDollar(item.health_care_proceed) | 0;
            item.debt_interest_proceed = this.decimalDollar(item.debt_interest_proceed) | 0;
            return item;
          });
        }
      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        this.$isLoading(false);
      });
    },

    // question 8
    on_click_have(number) {
      this.question.q8.have = number;

      this.updateQuestion();
    },

    on_click_what_quarters_2020_1() {
      this.question.q8.have_yes.what_quarters_2020.q1 =
          !this.question.q8.have_yes.what_quarters_2020.q1

      this.updateQuestion();
    },

    on_click_what_quarters_2020_2() {
      this.question.q8.have_yes.what_quarters_2020.q2 =
          !this.question.q8.have_yes.what_quarters_2020.q2;

      this.updateQuestion();
    },

    on_click_what_quarters_2020_3() {
      this.question.q8.have_yes.what_quarters_2020.q3 =
          !this.question.q8.have_yes.what_quarters_2020.q3;

      this.updateQuestion();
    },

    on_click_what_quarters_2020_4() {
      this.question.q8.have_yes.what_quarters_2020.q4 =
          !this.question.q8.have_yes.what_quarters_2020.q4;

      this.updateQuestion();
    },

    on_click_what_quarters_2021_1() {
      this.question.q8.have_yes.what_quarters_2021.q1 =
          !this.question.q8.have_yes.what_quarters_2021.q1

      this.updateQuestion();
    },

    on_click_what_quarters_2021_2() {
      this.question.q8.have_yes.what_quarters_2021.q2 =
          !this.question.q8.have_yes.what_quarters_2021.q2

      this.updateQuestion();
    },

    on_click_what_quarters_2021_3() {
      this.question.q8.have_yes.what_quarters_2021.q3 =
          !this.question.q8.have_yes.what_quarters_2021.q3

      this.updateQuestion();
    },

    on_click_what_quarters_2021_4() {
      this.question.q8.have_yes.what_quarters_2021.q4 =
          !this.question.q8.have_yes.what_quarters_2021.q4
      this.updateQuestion();
    },

    // question 9
    on_click_add_member() {
      if(this.answered === 1) return
      if (this.member.owner === "" || this.member.percentage == 0) return;

      let percent = this.question.q9.members
          .reduce((total, current) => total + current.percentage, 0) + this.member.percentage;

      if (percent > 100) {
        this.setAlert({
          message: "Make sure that the SUM of all percentages doesn't exceed 100 %",
          errors: null,
          color: "danger",
          flag: 'question9'
        });
        return;
      }

      this.question.q9.members.push({...this.member});
      this.member.owner = "";
      this.member.percentage = 0;

      this.updateQuestion();
    },

    on_click_check(item) {
      item.checked = !item.checked;
      this.updateQuestion();
    },

    on_click_remove_member(pos) {
      if(this.answered === 1) return

      this.question.q9.members = this.question.q9.members.filter((item, index) => pos !== index);
      this.updateQuestion();
    },

    on_click_select_all() {
      if (this.question.q9.members.length !== 0 && this.question.q9.members.length ===
          this.question.q9.members.reduce((total, current) => total + (current.checked ? 1 : 0), 0))
        this.question.q9.members.map((item) => item.checked = false);
      else {
        this.question.q9.members.map((item) => item.checked = true);
      }
      this.updateQuestion();
    },


    // question 11
    on_click_do_u_owe(number) {
      this.question.q11.do_u_owe = number;
      this.updateQuestion();
    },

    // submit
    onSubmit() {
      this.$isLoading(true);
      this.updateQuestion(1);

      this.$router.push({
        name: "documents",
        params: {
          id: this.id
        }
      })
    },

    goToDocuments(){
      this.$router.push({
        name: "documents",
        params: {
          id: this.id
        }
      })
    },

    OnCheckQuestionCompletedStatus(question) {
      // question 1
      this.questionCompletedStatus[1] = question.q1.do_u_pay;
      //question 2
      this.questionCompletedStatus[2] = question.q2.industry ? 1 : 0;
      // question 3
      this.questionCompletedStatus[3] = 0;
      if (question.q3.did_u_start_before_2019 === 1 ||
          (question.q3.after_2020_2_15.isAfter === 1 &&
              question.q3.after_2020_2_15.explain_how_ur_business_started !== -1) ||
          (question.q3.after_2020_2_15.isAfter !== 1 && question.q3.after_2020_2_15.what_quarter !== -1)) {
        this.questionCompletedStatus[3] = 1;
      }
      // question 4
      this.questionCompletedStatus[4] = 0;
      if (question.q4.did_ur_company_experience_full_or_partial === 0 ||
          (question.q4.did_ur_company_experience_full_or_partial_yes.explanation !== '' &&
              (
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q1 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q2 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q3 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2020.q4 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q1 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q2 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q3 ||
                  question.q4.did_ur_company_experience_full_or_partial_yes.what_quarters_fully_or_partially_2021.q4
              )
          )
      ) {
        this.questionCompletedStatus[4] = 1;
      }

      // question 5
      this.questionCompletedStatus[5] = 0;
      if (question.q5.did_u_have_any_supply_chain_disruption === 0 ||
          (
              question.q5.did_u_have_any_supply_chain_disruption_yes.what_was_disrupted !== '' &&
              question.q5.did_u_have_any_supply_chain_disruption_yes.from_what_supplier_process !== '' &&
              (
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q1 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q2 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q3 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2020.q4 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q1 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q2 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q3 ||
                  question.q5.did_u_have_any_supply_chain_disruption_yes.what_quarters_did_u_experience_supply_disruption_2021.q4
              )
          )
      ) {
        this.questionCompletedStatus[5] = 1;
      }

      // question 6
      this.questionCompletedStatus[6] = 0;
      if (question.q6.did_u_have_pivot === 0 ||
          (
              question.q6.did_u_have_pivot_yes.how_did_u_pivot !== '' &&
              (
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q1 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q2 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q3 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2020.q4 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q1 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q2 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q3 ||
                  question.q6.did_u_have_pivot_yes.pivot_what_quarters_did_u_experience_supply_disruption_2021.q4
              )
          )
      ) {
        this.questionCompletedStatus[6] = 1;
      }

      // question 7
      this.questionCompletedStatus[7] = 0;
      if (
          question.q7.did_u_get_ppp_loan === 0 || question.q7.did_u_get_ppp_loan_yes.loans.length
      ) {
        this.questionCompletedStatus[7] = 1;
      }

      // question 8
      this.questionCompletedStatus[8] = 0;
      if (
          question.q8.have === 0 ||
          (
              question.q8.have_yes.what_quarters_2020.q1 ||
              question.q8.have_yes.what_quarters_2020.q2 ||
              question.q8.have_yes.what_quarters_2020.q3 ||
              question.q8.have_yes.what_quarters_2020.q4
          ) ||
          (
              question.q8.have_yes.what_quarters_2021.q1 ||
              question.q8.have_yes.what_quarters_2021.q2 ||
              question.q8.have_yes.what_quarters_2021.q3 ||
              question.q8.have_yes.what_quarters_2021.q4
          )
      ) {
        this.questionCompletedStatus[8] = 1;
      }

      // question 9
      this.questionCompletedStatus[9] = 0;
      if (question.q9.members.length > 0) {
        this.questionCompletedStatus[9] = 1;
      }
      // question 10
      this.questionCompletedStatus[10] = 0;
      if (question.q10.title !== '')
        this.questionCompletedStatus[10] = 1;
      // question 11
      this.questionCompletedStatus[11] = 0;
      if (question.q11.do_u_owe === 0 || question.q11.do_u_owe_yes.owe_amount > 0) {
        this.questionCompletedStatus[11] = 1;
      }

      let sum = this.questionCompletedStatus
          .reduce((total, current) => total + current, 0);
      this.submitEnabled = sum === 11;
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "loggedIn"]),
    btnSubmitDisabled() {
      return !this.submitEnabled || this.question.q1.do_u_pay !== 1;
    },
    isQ9SelectedAll() {
      return this.question.q9.members.length !== 0 && this.question.q9.members.length ===
          this.question.q9.members.reduce((total, current) => total + (current.checked ? 1 : 0), 0);
    }
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.OnCheckQuestionCompletedStatus(newQuestion);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener("resize", this.calculateBannerSize);
  },
  beforeMount() {
    this.getQuestion();
  },
  unmounted() {
    window.removeEventListener("resize", this.calculateBannerSize);
  },
  created() {

  },
  filters: {},
  components: {
    CompanyName,
    Layout,
    BootStrapAlert,
    btnBack
  },
}
</script>

<style scoped lang="scss" src="./questionnarie.scss">
</style>